.timeline {
  $color: $gray-600;
  $timeline-font-color: $gray-400;
  text-align: start;
  counter-reset: list 0;
  margin-left: 12px;
  position: relative;
  flex-direction: column;

  &-item {
    list-style: none;

    padding-bottom: 15px;

    &:last-child {
      border-left-color: transparent;
    }
  }

  &-title {
    margin: 0;
    padding: 0 0 0 15px;
    position: relative;
    &:before {
      $size: 6px;
      color: $timeline-font-color;
      position: absolute;
      left: 0px;
      top: 8px;
      display: inline-block;
      width: $size;
      height: $size;
      line-height: $size;
      text-align: center;
      border-radius: 50%;
      background-color: $blue;
      content: "";
    }
  }

  &-text {
    margin: 0;
    padding: 24px;
  }
}
