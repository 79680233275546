.apple-pay {
  content: url(../icon/apple-pay.svg);
}

.discover {
  content: url(../icon/discover.svg);
}

.express {
  content: url(../icon/express.svg);
}

.google-pay {
  content: url(../icon/google-pay.svg);
}

.red-orange-card {
  content: url(../icon/red-orange-card.svg);
}

.visa {
  content: url(../icon/visa.svg);
}

.logo-doordash {
  content: url(../icon/logo-doordash.png);
}

.logo-ubereats {
  content: url(../icon/logo-ubereats.png);
}

.logo-postmates {
  content: url(../icon/logo-postmates.png);
}

.logo-chowbus {
  content: url(../icon/logo-chowbus.png);
}

.logo-amazon {
  content: url(../icon/logo-amazon.png);
}

.logo-lyft {
  content: url(../icon/logo-lyft.png);
}

.logo-google {
  content: url(../icon/logo-google.png);
}

.logo-instacart {
  content: url(../icon/logo-instacart.png);
}

.verification {
  content: url(../icon/verification.svg);
}
