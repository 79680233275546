.code-input-wrap {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 30px;

    .input {
        position: absolute;
        border: none;
        font-size: 40px;
        text-align: center;
        background-color: transparent;
        outline: none !important;
    }

    .display {
        width: 70px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 66px;
        position: relative;
        color: #000000;
        margin: auto 5px;
        border-bottom: 1px solid rgba(9, 34, 53, 0.64);
        font-weight: 400;
    }

    .shadows {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border-bottom: 1px solid rgba(9, 34, 53, 0.64);
    }
}
