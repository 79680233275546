button:focus {
    outline: none;
}
a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
    outline-color: transparent;
    outline-width: 0;
    outline-style: none;
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
}
.btn.disabled,
.btn:disabled {
    opacity: 1;
}
.btn {
    font-size: 16px;
    font-weight: $bold;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    border-radius: 8px;
}
.btn-submit {
    margin-top: 40px;
}
.btn-lg {
    height: 39px;
}
.btn-sm {
    height: 28px;
    font-size: 12px;
}
a,
.btn-link {
    height: inherit;
    font-weight: $semi-bold;
    color: $primary;
    text-decoration: none;
    box-shadow: none !important;
    &:hover {
        color: rgba($primary, 0.8);
        background: none;
        background-color: none;
        text-decoration: none;
    }
    &:active {
        color: rgba($primary, 0.8);
        box-shadow: none;
        background: none;
        text-decoration: none;
    }
}
.btn-success {
    color: #fff;
    background-color: $green;
    border-color: $green;
    &:hover,
    &.disabled {
        background-color: rgba($green, 0.8);
        border-color: rgba($green, 0.8);
    }
    &:focus {
        background-color: $green;
        border-color: $green;
    }
    &:active {
        background-color: $green !important;
        border-color: $green !important;
        box-shadow: 0 0 0 0 $green !important;
    }
}

.btn-secondary {
    background-color: $gray-600;
    color: #405668;
    font-size: 16px;
    border-color: $gray-600;
    font-weight: $bold;
    max-width: 200px;
    border-radius: 8px;
    margin: 40px auto 0 auto;
    &.btn-sm {
        font-size: 10px;
    }
    &:hover,
    &:focus {
        background-color: $gray-500;
        border-color: $gray-500;
        color: #405668;
    }
}

.btn-outline-secondary {
    border-color: $gray;
    color: $gray;
}

.btn-secondary:not(:disabled):not(.disabled):active {
    // background-color: rgba($color: #000000, $alpha: 0);
    // border-color: rgba($color: #000000, $alpha: 0);
    background-color: #e1e5e9;
    border-color: #e1e5e9;
    color: #405668;
}
// .btn:not(:disabled):not(.disabled):active,
// .btn:not(:disabled):not(.disabled).active,
// .show > .btn.dropdown-toggle {
//   background-color: inherit !important;
//   border-color: inherit !important;
//   color: inherit !important;
// }

.btn-remove {
    i {
        color: #e1e5e9;
    }
    color: $gray-400;
    font-weight: $semi-bold;
    border: none;
    &:hover {
        color: $gray-400;
        box-shadow: none;
        border: none;
    }
}

.btn-primary {
    font-weight: $semi-bold;
    max-width: 320px;
    margin: 40px auto 0 auto;
    background-color: $primary;
    border-color: $primary;
    &:hover,
    &:focus,
    &:active {
        background-color: $primary;
        border-color: $primary;
        box-shadow: none;
    }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    background-color: $primary;
    border-color: $primary;
    box-shadow: none;
}

.outline-btn {
    border: 1px solid $primary;
    border-radius: 8px;
    font-weight: $semi-bold;
    background-color: white;
    color: $primary;
    &:hover,
    &:active,
    &:focus {
        background-color: rgba($primary, 0.08);
        border: 1px solid $primary;
        border-radius: 8px;
        color: $primary;
    }
    &.gray {
        border: 1px solid $gray-500;
        color: $gray-300;
    }
    &.disabled {
        color: $primary;
    }
}

.btn-popover {
    display: inline-block;
    color: $gray;
}

.btn-inline {
    border: none;
    box-shadow: none;
}

.popover {
    border-color: rgba(0, 0, 0, 0.1);
}
.popover-inner {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
    border-top-color: rgba(0, 0, 0, 0.1);
}
.btn-rounded {
    border-radius: 2em;
}
.button-row {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    &.btn-group-next {
        .btn {
            &:first-child {
                max-width: 150px;
                display: inline;
                margin-right: 30px;
            }
        }
    }
}

.btn-outline-inner {
    height: 39px;
    width: -webkit-fill-available;
    font-size: 14px;
    font-weight: $regular;
    color: #828b92;
    box-shadow: none;
    border: 1px dashed transparent;
    &.new-option {
        margin-top: 10px;
    }
    background: linear-gradient(#ffffff, #ffffff) padding-box,
        repeating-linear-gradient(
            -45deg,
            #ffffff 0,
            #ffffff 6px,
            #e1e5e9 6px,
            #e1e5e9 12px
        );
    &:active,
    &:focus {
        color: #828b92;
        box-shadow: none;
        border: 1px dashed transparent;
        background: linear-gradient(#ffffff, #ffffff) padding-box,
            repeating-linear-gradient(
                -45deg,
                #ffffff 0,
                #ffffff 6px,
                #e1e5e9 6px,
                #e1e5e9 12px
            );
    }
    &:hover {
        border: 1px dashed transparent;
        background: linear-gradient(#ffffff, #ffffff) padding-box,
            repeating-linear-gradient(
                -45deg,
                #ffffff 0,
                #ffffff 6px,
                $primary 6px,
                $primary 12px
            );
        color: $primary;
    }
}

.btn-white {
    border: 1px solid $primary;
    background-color: #fff;
    color: $primary;
    &:disabled {
        border: 1px solid rgba($primary, 0.8);
        background-color: #fff;
        color: rgba($primary, 0.8);
    }
}

.gray-link {
    background: none;
    &:active {
        background: none;
        color: #828b92 !important;
        background-color: none !important;
    }
    &:hover {
        background: none;
    }
    &:focus {
        background: none;
    }
    &.btn-secondary {
        &:not(:disabled) {
            &:not(:disabled) {
                &:active {
                    background: none;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

.btn-outline-primary {
    border: 1px solid $primary;
    background-color: white;
    color: $primary;
    &:hover,
    &:focus,
    &.active,
    .move {
        border: 1px solid $primary !important;
        background-color: rgba($primary, 0.08) !important;
        color: $primary !important;
    }
}

.btn-gray {
    background-color: $gray-600;
    border: 1px $gray-600;
    color: $gray-300;
    box-shadow: none;
    &:focus,
    &:hover {
        background-color: $gray-400;
        border: 1px $gray-600;
        color: $gray-200;
    }
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #f4f6f8;
    border-color: #f4f6f8;
    color: #405668;
    cursor: no-drop;
}

.btn-lg {
    height: 52px;
}

.button-row.btn-group-next .btn:first-child {
    max-width: 176px;
    margin-right: 16px;
}

@include media-breakpoint-down(sm) {
    .btn-primary {
        max-width: 100%;
    }
}

@include media-breakpoint-down(sm) {
    .button-row {
        flex-direction: column-reverse;
        &.btn-group-next {
            .btn {
                margin-bottom: 10px;
                &:first-child {
                    max-width: 100%;
                    display: block;
                    margin-right: 0px;
                    background-color: transparent;
                    border-color: transparent;
                }
            }
        }
    }
}
