.auth-layout {
    background-color: #fafbfc;
    min-height: 100vh;

    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%;
}

.auth-main {
    min-height: calc(100vh - 100px);
}

.main-layout {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    font-size: 14px;
    font-weight: $regular;
    > * {
        font-size: 14px;
        font-weight: $regular;
    }
    .main-nav {
        padding: 0px;
        border-bottom: 1px solid $border-color;
        position: relative;
        .head-img {
            position: absolute;
            width: 60px;
            height: 60px;
            background: white;
            border-radius: 100%;
            z-index: 2;
            bottom: 0px;
            left: 50%;
            transform: translate(-30px, 30px);
            border: 1px solid $border-color;
            background-size: cover;
            background-position: center center;
        }
        .navbar {
            padding: 0;
            .nav-item {
                margin-right: 30px;

                a:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .main-content {
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 86px);
        padding: 0 0px 70px 0px;
        background-color: white;
        .terms-bar {
            background-color: $background-color;
            margin-top: 10px;
            border-radius: 8px;
            padding: 16px 0;
        }
        .alert-bar {
            height: 39px;
            width: 100%;
            background-color: rgba(#f59762, 0.1);
            color: #f59762;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            line-height: 39px;
            text-align: center;
            padding: 0 10px;
            font-size: 14px;
            font-weight: $regular;
            margin-top: 10px;
        }
        .phone-resend {
            height: 40px;
            width: 230px;
            background-color: $background-color;
            margin: 0 auto 30px auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 10px;
        }
    }
}
.sales-tax-field {
    width: 153px !important;
    min-width: 153px !important;
    margin-right: 15px;
}
@include media-breakpoint-down(sm) {
    .main-layout {
        .main-nav {
            .head-img {
                transform: translate(-30px, 70px);
            }
        }
        .main-content {
            padding-top: 10px;
        }
    }
    .sales-tax-field {
        width: 100px !important;
        min-width: 100px !important;
    }
}
