.basic-single {
  border: 1px solid #e1e5e9;
  border-radius: 4px;
  min-height: 39px;
  width: 100%;
  &:hover {
    border-color: $primary;
  }
  .select__control {
    border: 0px;
    border-radius: 4px;
    text-align: center;
    box-shadow: none;
    &:hover {
      border-color: $primary;
    }
  }
  .select__control--is-focus,
  .select__control--menu-is-open {
    border-color: $primary;
    box-shadow: none;
  }
  .select__single-value {
    font-size: 14px;
    font-weight: $regular;
    color: $purple;
  }
  .select__indicator-separator {
    width: 0px !important;
  }
  .select__indicator {
    padding: 6px;
  }
  .select__menu {
    font-size: 14px;
    font-weight: $regular;
    border-top: none;
    margin: 1px 0 0 0;
    box-shadow: 0px 6px 10px rgba($color: #828b92, $alpha: 0.1);
    .select__menu-list {
      padding: 10px 0;

      .select__option--is-selected {
        background-color: $primary;
        font-size: 14px;
        font-weight: $regular;
      }
    }
  }
}
