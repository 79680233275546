@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

$font-family-sans-serif: "Open Sans", sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$bold: 700;
$semi-bold: 600;
$regular: 400;

$disable-color: #121217;
$black: #000016;
$field-color: #e1e5e9;
$separator-color: #363636;
$card-color: #1c1c21;
$board-color: #1e1e21;

$gray-200: #092235;
$gray-300: #405668;
$gray-400: #828b92;
$gray-500: #e1e5e9;
$gray-600: #f4f6f8;

$cream-100: rgba(233, 233, 233, 1); //#E9E9E9
$cream-75: rgba(233, 233, 233, 0.75);
$cream-50: rgba(233, 233, 233, 0.5);
$cream-25: rgba(233, 233, 233, 0.25);

$darkest: $gray-200;
$dark: $gray-300;
$orange: #f59762;
$yellow: #ffd600;
// $primary: #008dff;
$primary: #00a5eb;
$cardcolor: #262d45;
$red: #ed5f65;
$green: #03c9a9;
$gray: #8898aa;
$purple: #32325d;

$blue: $primary;
$info: $primary;
$success: $green;
$warning: $orange;
$danger: $red;

$pickup: #0a78b4;
$delivery: #2bbea7;
$curbside: #0a78b4;
$disabled-backcolor: #f4f6f8;
$small-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

$border-color: #e1e5e9;
$border-color-light: $cream-100;
$body-bg: #fff;
$label-color: $gray-400;
$body-color: $gray-300;
$input-color: $gray-200;
$border-color: $gray-500;
$sidebar-color: #ffffff;
$background-color: #fafbfc;

$theme-colors: (
    "black": $black,
);
