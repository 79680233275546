.text-regular {
  font-weight: $bold;
  font-size: 14px;
  color: $cream-75 !important;
  line-height: 1.5;
  font-weight: $regular;
  &.selected {
    color: $cream-100 !important;
  }
}

.text-field {
  font-weight: $bold;
  font-size: 14px;
  color: $cream-100 !important;
  line-height: 1.3;
}

.text-placehoder {
  font-weight: $bold;
  font-size: 14px;
  color: $cream-25 !important;
  line-height: 1.3;
}

label {
  font-size: 12px;
  font-weight: $semi-bold;
  color: $label-color;
}

.f-10 {
  font-size: 10px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-22 {
  font-size: 22px;
}

.semi-bold {
  font-weight: $semi-bold;
}

.regular {
  font-weight: $regular;
}

.bold {
  font-weight: $bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $semi-bold;
  color: $darkest;
}

.text-red {
  font-weight: $semi-bold;
  color: $red;
}
.text-gray {
  font-weight: $semi-bold;
  color: $gray;
}
.text-blue {
  font-weight: $semi-bold;
  color: $primary;
}
.text-green {
  font-weight: $semi-bold;
  color: $green;
}
.text-orange {
  font-weight: $semi-bold;
  color: $orange;
}
.text-black {
  color: $black;
}
