.card {
}
.info-card {
    padding: 15px 30px;
    border-radius: 8px;

    .title {
        font-size: 14px;
        font-weight: $semi-bold;
        color: $gray-200;
    }
    .sub-title {
        font-size: 12px;
        font-weight: $regular;
        color: #828b92;
    }
    &.gray {
        background-color: #fafbfc;
    }
    .plan-detail {
        margin: 10px 0;
        color: $gray-400;
        font-size: 14px;
        font-weight: $regular;
        &.semi-bold {
            font-weight: $semi-bold;
        }
        &.black {
            font-weight: $semi-bold;
            color: black;
        }
    }
    .line {
        height: 1px;
        border-top: 1px dashed #e1e5e9;
        border-radius: 0;
        width: 100%;
        margin: auto 10px;
    }

    .black {
        .line {
            border-color: #000;
        }
    }

    .line-head {
        white-space: nowrap;
        .trial-normal {
            display: flex;
        }
        .trial-mobile {
            display: none;
        }

        @media (max-width: 375px) {
            .trial-normal {
                display: none;
            }
            .trial-mobile {
                display: flex;
            }
        }
    }
}
.sps-plan-card {
    position: relative;
    padding: 15px 30px;
    border-radius: 8px;
    border: 1px solid $border-color;
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    // transition: 1s;
    .plan-title {
        font-size: 18px;
        font-weight: $bold;
        color: $gray-200;
    }
    .plan-sub-title {
        font-size: 16px;
        font-weight: $regular;
        color: #828b92;
    }

    .plan-cur-span {
        background-color: #fafbfc;
        height: 24px;
        width: 68px;
        border-radius: 18px;
        font-size: 10px;
        font-weight: $bold;
        color: black;
        margin: auto 0;
        text-align: center;
        justify-content: center;
        line-height: 24px;
    }
}

.plan-card {
    box-sizing: border-box;
    .plan-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            font-size: 24px;
            color: #00a5eb;
        }
    }
}

.plan-active,
.plan-card:hover {
    margin: 8px 0;
    border: 2px solid #092235;
    border-radius: 8px;
}

.plan-active,
.plan-card:not(.plan-current):hover {
    //   .plan-title {
    //     color: #fff;
    //   }
    //   .plan-sub-title {
    //     color: #fff;
    //   }
    // .recommend-hand {
    //     display: block;
    //     animation: ease-out;
    //     animation-name: run;
    //     animation-duration: 300ms;
    // }

    // .ok-hand {
    //     display: block;
    // }
    // &.plan-green {
    //     border-color: #fff;
    //     background: #4ed38f;
    //     background: linear-gradient(45deg, #00b6aa 0%, #4ed38f 100%);
    // }
    // &.plan-blue {
    //     border-color: #fff;
    //     background: #209bff;
    //     background: linear-gradient(45deg, #1377c0 0%, #209bff 100%);
    // }
    // &.plan-orange {
    //     border-color: #fff;
    //     background: #fbab7e;
    //     background: linear-gradient(45deg, #ef8448 0%, #fbab7e 100%);
    // }
}

.plan-total-preview {
    background-color: $background-color;
    border: 1px solid $background-color;
    border-radius: 8px;
    color: $darkest;
    margin-bottom: 30px;
    padding: 15px 20px;
}

@include media-breakpoint-down(sm) {
    .plan-summary {
        &.info-card {
            padding: 30px;
        }
        @media (max-width: 375px) {
            &.info-card {
                padding: 30px 15px;
            }
        }
    }
}

.stripe_form {
    margin-top: 16px;
}

.business-tips {
    width: 100%;
    margin-top: 8px;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 18.75px;
    font-weight: 400 !important;
    color: #092235;
    background-color: #fafbfc;
    border-radius: 8px;
}

.plan-summary {
    padding: 24px;
    .plan-title {
        font-size: 14px;
        line-height: 1.4;
        color: #092235;
        font-weight: 700;
    }
    .plan-tips {
        font-size: 16px;
        line-height: 18.75px;
        color: rgba(9, 34, 53, 0.64);
    }
}

.plan-tips-spanish {
    width: 450px;
}
