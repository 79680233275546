select:not([multiple]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: none;
    padding: 0.375rem 0.75rem;
}
.form-card {
    max-width: 524px;
    margin: 60 auto;
    border: none;
    &.welcome-call {
        max-width: 100%;
        .calendly-outline {
            height: 1100px;
            background-color: $white;
        }
        .welcome-call-container {
            display: flex;
            justify-content: center;
            .welcome-call-language {
                display: flex;
                flex-direction: row;
                max-width: 500px;
                width: 80%;
                display: flex;
                justify-content: space-evenly;
                .welcome-call-language-btn {
                    width: 160px;
                    height: 51px;
                    border-radius: 8px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #092235;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;
                    background-color: #f4f6f8;
                }
                .welcome-call-language-btn-selected {
                    border: 2px solid #092235;
                    background-color: #fff;
                }
            }
        }
        .welcome-call-no {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 48px;
            .welcome-call-note {
                width: 512px;
                padding: 24px 48px;
                border-radius: 8px;
                border: 1px solid #e1e5e9;
                .welcome-call-title {
                    font-size: 16px;
                    font-weight: 700;
                    color: #092235;
                    padding-bottom: 16px;
                }
                .welcome-call-text {
                    font-size: 16px;
                    color: rgba(9, 34, 53, 0.64);
                }
            }
        }
    }
    .completed-title {
        max-width: 400px;
        margin-bottom: 32px;
        font-size: 18px;
        font-weight: 700;
        color: #092235;
        text-align: center;
    }
    .completed-list {
        margin-bottom: 32px;
        max-width: 400px;
        .completed-list-item {
            margin-bottom: 16px;
            padding: 16px 24px;
            border-radius: 8px;
            border: 1px solid #e1e5e9;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .completed-list-item-text {
                line-height: 18.75px;
                font-size: 16px;
                color: rgba(9, 34, 53, 0.64);
            }
            .completed-list-icon {
                i {
                    font-size: 16px;
                    color: #00a5eb;
                }
                .icon-no {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    border: 1px solid #e1e5e9;
                }
            }
        }
    }
    .completed-button {
        max-width: 400px;
    }
    .essential-pricing {
        padding: 16px 24px;
        .er-title {
            font-size: 14px;
            line-height: 1.4;
            font-weight: 700;
            color: #092235;
        }
        .er-drag-bar {
            padding: 6px 0;
        }
        .er-info {
            display: flex;
            justify-content: space-between;
            .er-info-item {
                .er-info-title,
                .er-info-price,
                .er-info-type {
                    font-size: 14px;
                    line-height: 1.4;
                    color: #092235;
                }
                .er-info-price {
                    .er-info-price-blue {
                        text-decoration-line: line-through;
                        color: #00a5eb;
                    }
                }
                .er-info-type {
                    font-size: 12px;
                    color: rgba(9, 34, 53, 0.64);
                }
            }
            .er-info-item-right {
                text-align: right;
            }
        }
    }
    .payment-detail {
        padding: 16px 24px;
        .payment-title {
            padding-bottom: 16px;
            color: #092235;
            font-size: 14px;
            line-height: 1.4;
            font-weight: 700;
            border-bottom: 1px solid #e1e5e9;
        }
        .payment-detail-item {
            padding: 12px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px dashed #e1e5e9;
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
            .pdi-left {
                font-size: 14px;
                line-height: 1.4;
                color: #092235;
            }
            .pdi-right {
                text-align: right;
                line-height: 1.4;
                .pdi-price {
                    font-size: 14px;
                    color: #092235;
                    .pdi-price-blue {
                        padding-right: 4px;
                        text-decoration-line: line-through;
                        color: #00a5eb;
                    }
                }
                .pdi-type {
                    font-size: 12px;
                    color: rgba(9, 34, 53, 0.64);
                }
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    .form-card {
        &.welcome-call {
            .calendly-outline {
                height: 1500px;
            }
        }
    }
}

.custom-control {
    .custom-control-label::before {
        // border-color: #00a5eb !important;
        // background-color: #00a5eb !important;
        border: rgba(9, 34, 53, 0.64) solid 2px;
        border-radius: 0.1rem;
    }
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #00a5eb;
    background-color: #00a5eb;
}

.form-control {
    border-radius: 8px;
    border-color: $border-color;
    font-size: 16px;
    color: $gray-200;
    height: 43px;
    padding: 12px 16px;

    &.caret {
        padding-right: 2rem;
        background-image: linear-gradient(45deg, transparent 50%, #222 50%),
            linear-gradient(135deg, #222 50%, transparent 50%);
        background-position: calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px), 100% 0;
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
        background-repeat: no-repeat;
    }

    &:focus,
    &:hover {
        outline: none;
        box-shadow: none;
        border-color: rgba($primary, 0.7);
    }

    &:disabled {
        background-color: $disabled-backcolor;
        &:focus,
        &:hover {
            outline: none;
            box-shadow: none;
            border-color: rgba($disabled-backcolor, 0.7);
        }
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray-500;
        opacity: 1;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $gray-500;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $gray-500;
    }

    + .form-control {
        margin-top: 0.7rem;
    }

    &:disabled,
    &[readonly] {
        -webkit-text-fill-color: $purple; /* Override iOS / Android font color change */
        -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
    }
}

.form-row {
    margin-right: -10px;
    margin-left: -10px;
}

.form-row > .col,
.form-row > [class*="col-"] {
    padding-right: 16px;
    padding-left: 16px;
}

.form-group {
    margin-bottom: 20px;
    width: auto;
    min-width: 153px;
    float: none;

    font-size: 12px;
    font-weight: $bold;
}
.form-p-x {
    padding: 0 6px;
}

@include media-breakpoint-down(sm) {
    .form-group {
        min-width: 50px;
    }
}
.mb-32 {
    margin-bottom: 32px;
}
.terms-check-bar {
    padding: 16px 24px;
    border-radius: 8px;
    background-color: #fafbfc;
    .bar-p0 {
        padding: 0;
        .custom-control {
            top: 8px;
            padding-right: 10px;
        }
        @media (max-width: 425px) {
            .custom-control {
                top: 18px;
            }
        }
    }
}

.policy-check-bar {
    padding: 24px 48px;
    border-radius: 8px;
    border: 1px solid #e1e5e9;
    .bar-p0 {
        padding: 0;
        .custom-control {
            top: 8px;
            padding-right: 16px;
        }
        @media (max-width: 425px) {
            .custom-control {
                top: 18px;
            }
        }
    }
}
.failure-warning {
    font-size: 18px;
    padding: 30px 40px;
    background-color: #fff8e3;
    margin-top: 10px;
    margin-bottom: 60px;
    border-radius: 4px;
    text-align: center;
}
.cc-card-group {
    padding-top: 16px;
    i {
        margin-right: 8px;
        height: 32px;
    }
}
.company-logo-group {
    display: flex;
    .company-logo-content {
        display: inline-flex;
        width: 90px;
        height: 36px;
        justify-content: center;
        background-color: #fafbfc;
        margin-right: 8px;
        margin-top: 8px;
        i {
            font-size: 12px;
            margin: auto;
            width: 90px;
            height: 36px;
        }
    }
}

@media (max-width: 425px) {
    .policy-check-bar {
        padding: 24px 24px;
    }
    .company-logo-group {
        display: initial;
    }
}
.policy-bar {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 32px;
    padding: 0px 48px;
    border: 1px solid #e1e5e9;
    .policy-half-block {
        width: 100%;
        display: block;
    }
    .policy-half-inlineblock {
        width: 100%;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .policy-quarter-left {
            font-size: 16px;
            color: $gray-200;
        }
        .policy-quarter-right {
            button {
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
    .borderBottom {
        border-bottom: 1px solid #e1e5e9;
    }
}
@media (max-width: 425px) {
    .policy-bar {
        padding: 0px 24px;
    }
}

.input-group > .form-control {
    &:focus {
        outline: none;
        box-shadow: none;
    }
}
.input-group-text {
    border: 0px;
    border-radius: 4px;
    background-color: #f4f6f8;
    border: 1px solid $border-color;
    font-size: 14px;
    height: 43px;
}

.input-group > .form-control {
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
    // border-left-width: 0;
    text-align: right;
}

.input-group > .input-group-prepend:not(:first-child) {
    .input-group-text {
        border-left-width: 0;
    }
}

.input-group > .input-group-prepend:last-child {
    .input-group-text {
        border-radius: 0 4px 4px 0;
    }
}
.input-group > .input-group-prepend:first-child {
    .input-group-text {
        border-right-width: 0;
    }
}

.price.input-group .form-control {
    padding-left: 0;
}
.form-check-label {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    color: $dark;
}

.address-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .address-row-city {
        width: calc(33.3% - 10px);
    }
    .address-row-state {
        width: calc(33.3% - 10px);
    }
    .address-row-zipcode {
        width: calc(33.3% - 10px);
    }
}
@media (max-width: 425px) {
    .address-row {
        .address-row-city {
            width: 100%;
        }
        .address-row-state {
            width: calc(50% - 10px);
        }
        .address-row-zipcode {
            width: calc(50% - 10px);
        }
    }
}

.loading-text {
    width: 120px;
    display: flex;
    margin: auto;
    white-space: nowrap;
}
.payout-title-img {
    margin-bottom: 32px;
    padding: 24px;
    background-color: #fafbfc;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-content: center;
    img {
        max-width: 100%;
        width: 368px;
        height: 185px;
    }
}

.page-title {
    margin-top: 48px;
}

@media (max-width: 425px) {
    .page-title {
        margin-top: 80px;
    }
}
