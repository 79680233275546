.primary-table {
  .table {
    th,
    td {
      padding: 10px 15px;
      border-top: none;
    }
    thead th {
      color: $cream-50;
      border-bottom: 1px dotted $separator-color;
      font-size: 12px;
      font-weight: $bold;
    }
    tbody td {
      color: $cream-75;
      border-bottom: none;
      font-size: 16px;
      font-weight: $regular;
    }
  }
}
