body {
    font-size: 14px;
    color: $dark;
}

h1 {
    font-size: 18px;
    font-weight: $bold !important;
    margin: 0 auto 32px auto;
    text-align: center;
    max-width: 440px;
    line-height: 1.2;
}

label {
    font-weight: 400 !important;
    color: rgba(9, 34, 53, 0.64) !important;
}

@include media-breakpoint-down(sm) {
    h1 {
        font-size: 16px;
    }
}

.main-content {
    position: relative;
    overflow-y: auto;
    background-color: #fafbfc;
    .form-card {
        margin: auto;
    }
}

.page-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

label {
    color: $cream-50;
    position: relative;
    font-weight: $bold;
    font-size: 12px;
    line-height: 1.3;
    margin-bottom: 5px;
}

.divider {
    height: 0;
    display: block;
    width: 100%;
    border-top: 1px solid;
    margin: 1rem 0;
}

.w-action {
    width: 46px;
}

.w-120 {
    width: 120px;
}

.w-150 {
    width: 150px;
}

.w-160 {
    width: 160px;
}

.w-400 {
    width: 400px;
}

.mw-400 {
    max-width: 400px;
}

.w-500 {
    width: 500px;
}

.mt-30 {
    margin-top: 30px;
}
.v-hidden {
    visibility: hidden;
}

.page-item {
    .page-link {
        border-radius: 100%;
        width: 26px;
        height: 26px;
        line-height: 26px;
        padding: 0;
        text-align: center;
        margin: 2.5px;
        text-decoration: none !important;
        background-color: $field-color;
        border: none;
        color: $white;
        &:hover {
            text-decoration: none !important;
        }
    }
    &.active .page-link {
        background-color: $white;
        color: $black;
    }
}

.pagination-sm {
    margin: 30px 0;
    .page-item {
        &:first-child .page-link {
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
        }

        &:last-child .page-link {
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
        }
    }
}

.terms-of-service {
    p,
    li {
        font-size: 20px;
        font-family: "Roboto", Sans-serif;
        color: rgba(7, 32, 62, 0.7019607843137254);
    }
}

.modal-terms {
    h2 {
        font-size: 18px;
    }
}

article.terms {
    max-width: 800px;
    font-size: 14px;
    margin: auto;
    color: $gray-900;
    text-align: justify;

    h1 {
        font-size: 20px;
        font-weight: $bold;
        text-align: center;
        color: $gray-200;
    }
    h2 {
        font-size: 16px;
        font-weight: $regular;
        text-align: center;
        color: #828b92;
    }
    a {
        overflow-wrap: break-word;
    }
}
