.questionnaire {
    display: flex;
    align-items: center;
    flex-direction: column;
    .qn-title {
        width: 80%;
        max-width: 410px;
        text-align: center;
        .qn-title-text {
            color: #092235;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
        }
    }
    .qn-language {
        display: flex;
        flex-direction: row;
        max-width: 500px;
        width: 80%;
        display: flex;
        justify-content: space-evenly;
        .qn-language-btn {
            width: 160px;
            height: 40px;
            border-radius: 8px;
            // border: 1px solid #092235;
            font-size: 14px;
            font-weight: bold;
            color: #405668;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            background-color: #f4f6f8;
        }
        .qn-language-btn-selected {
            border: 2px solid #092235;
            background-color: #fff;
        }
    }
    .qn-submit {
        width: 100%;
        max-width: 512px;
        .btn-primary.disabled,
        .btn-primary:disabled {
            background-color: #f4f6f8;
            border-color: #f4f6f8;
            color: #405668;
            cursor: no-drop;
        }
    }
    .qn-form {
        margin-top: 47px;
        .qn-form-blank {
            height: 32px;
        }
        .qn-form-line {
            margin-top: 24px;
            margin-bottom: 28px;
            border-top: 1px solid #e1e5e9;
        }
        .qn-form-title {
            margin-bottom: 8px;
            font-weight: 700;
            font-size: 12px;
            color: rgba(9, 34, 53, 0.64);
        }
        .loc_add_query {
            margin-bottom: 8px;
        }
        .qn-form-input {
            margin-bottom: 28px;
        }
        .qn-form-file {
            height: 35px;
            border-radius: 8px;
            background-color: #fafbfc;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            margin-bottom: 8px;
            .form-file-name {
                font-size: 14px;
                color: #405668;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 20px;
                .fa-file {
                    width: 16px;
                    height: 16px;
                    color: #e1e5e9;
                    padding-right: 3px;
                }
            }
            .form-file-delete {
                cursor: pointer;
            }
        }
        .qn-form-upload {
            height: 68px;
            border-radius: 4px;
            border: 1px dashed #e1e5e9;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 28px;
            cursor: pointer;
            position: relative;
            .form-upload-title {
                font-size: 14px;
                color: #092235;
                .fa-file-upload {
                    color: #092235;
                    width: 14px;
                    height: 14px;
                }
            }
            .form-upload-tip {
                font-size: 12px;
                color: #828b92;
            }
            .form-upload-input {
                height: 68px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    .qn-cart {
        max-width: 512px;
        position: relative;
        border-radius: 8px;
        border: 1px solid #e1e5e9;
        margin-bottom: 40px;
        .qn-cart-title {
            padding: 0 4px;
            position: absolute;
            left: 28px;
            top: -12px;
            background-color: #fff;
        }
        .qn-cart-container {
            padding: 32px 48px;
            .loc-title {
                color: #092235;
                font-size: 16px;
                font-weight: 500;
                line-height: 18.75px;
                margin-bottom: 17px;
                margin-top: 17px;
            }
        }
    }
    .qn-line {
        .qn-line-question {
            color: #092235;
            font-size: 16px;
            font-weight: 500;
            line-height: 18.75px;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
            .line-question-why {
                width: 100px;
                height: 30px;
                border-radius: 8px;
                background-color: #f4f6f8;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-size: 12px;
                font-weight: bold;
                .question-why-text {
                    letter-spacing: -0.5px;
                }
            }
        }
        .qn-line-answer {
            background-color: #fafbfc;
            border-radius: 8px;
            padding: 10px 16px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .line-answer-text {
                font-size: 14px;
                color: #405668;
                padding-left: 8px;
            }
            .qn-select-icon {
                .select-icon-no {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    border: 1px solid #e1e5e9;
                    background-color: #fff;
                }
                .select-icon-yes {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background-color: #008dff;
                    position: relative;
                    .icon-yes-check {
                        width: 4.5px;
                        height: 8px;
                        border-radius: 1px;
                        border-color: #fff;
                        border-style: solid;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                        position: absolute;
                        top: 2px;
                        left: 4.5px;
                    }
                }
            }
        }
        .qn-line-input {
            margin-bottom: 8px;
        }
    }
    .qn-dialog {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        .qn-dialog-shadow {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
        }
        .qn-dialog-container {
            margin-top: 64px;
            width: 90%;
            height: 80%;
            max-width: 520px;
            max-height: 706px;
            padding: 48px;
            position: relative;
            z-index: 2;
            background-color: #fff;
            border-radius: 8px;
            overflow-y: auto;
            .qn-dialog-close {
                position: absolute;
                top: 16px;
                right: 24px;
                .fa-times {
                    cursor: pointer;
                    color: #e1e5e9;
                    font-size: 24px;
                }
            }
            .qn-dialog-text-bold {
                font-weight: bold;
                font-size: 16px;
                color: #092235;
                line-height: 18.75px;
                margin-bottom: 16px;
            }
            .qn-dialog-text {
                font-size: 16px;
                color: rgba(9, 34, 53, 0.64);
                line-height: 18.75px;
                margin-bottom: 16px;
            }
            .qn-dialog-img {
                width: 100%;
                max-width: 400px;
                max-height: 272.75px;
            }
            .qn-dialog-btn {
                margin-top: 32px;
                width: 320px;
                height: 51px;
            }
        }
    }
}

@media (max-width: 500px) {
    .qn-cart-container {
        padding: 32px 16px !important;
    }
    .qn-cart-title {
        left: 12px !important;
    }
    .form-file-name {
        max-width: 270px;
    }
}
