/* Change the white to any color ;) */
/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  border: 1px solid $border-color;
  -webkit-text-fill-color: $black;
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
  transition: background-color 5000s ease-in-out 0s;
  color: $black;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $primary;
  -webkit-text-fill-color: $black;
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
  transition: background-color 5000s ease-in-out 0s;
}

input {
  background-color: $white;
}
